// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "$bOQOPOOOrOPO'#CaO}OPO'#CaO!SOPO'#C_OTOPO'#C_OTOPO'#C_O![OPO'#CnO!aOQO'#C^OOOO'#Cm'#CmQQOPOOOOOO'#Cj'#CjOOOO,58{,58{O!kOPO,58{OOOO,59Z,59ZO!pOPO,58yOOOO-E6m-E6mOTOPO,58yOOOO,59Y,59YOOOO-E6l-E6lOOOO,58x,58xOOOO-E6k-E6kO!xOPO1G.gP!}OPO'#CoO#SOPO1G.eO#[OPO7+$ROOOO<<Gm<<Gm",
  stateData: "#g~OSTOUPOVPOWPOXPOYPOZPO[PO]QO_PO~OfYO`TXhTX~Oe[O~O`]OhRX~OhaO~OicOjcO~PQOfYO~O`]OhRa~OghO~O`]O~O`]OhRi~OfYO`TqhTq~O",
  goto: "!ndPPeiPnPPPPPPPPzPP!T!Z!bTWOXVUOVXUROVXS^STRg`QZPQe[RihQXORdXSVOXRbVUSOVXS_S`R`T",
  nodeNames: "⚠ Level Row Cell Glitch Block NormalFloor Wall Box Trigger Charge Goal Enemy Door Id Player Concat",
  maxTerm: 26,
  skippedNodes: [0],
  repeatNodeCount: 3,
  tokenData: "'_~RkXY!vYZ#kZ^!vpq!vst$bxy$|yz%R{|%W!d!e%]!e!f%w!f!g%|!g!h&R!i!j&j!p!q&o!r!s&t!v!w&y!y!z'O#R#S'T#y#z!v$f$g!v#BY#BZ!v$IS$I_!v$I|$JO!v$JT$JU!v$KV$KW!v&FU&FV!v~~'YP!{YhPX^!vpq!v#y#z!v$f$g!v#BY#BZ!v$IS$I_!v$I|$JO!v$JT$JU!v$KV$KW!v&FU&FV!vR#rYiQhPX^!vpq!v#y#z!v$f$g!v#BY#BZ!v$IS$I_!v$I|$JO!v$JT$JU!v$KV$KW!v&FU&FV!v~$eR!Q![$n!c!}$n#T#o$n~$sRf~!Q![$n!c!}$n#T#o$n~%ROe~~%WOg~~%]O`~~%`S!h!i%l!t!u%q!z!{%l!{!|%l~%qOW~~%tP!h!i%l~%|OY~~&RO]~~&UQ!R!S&[!S!T&a~&aO[~~&dQ!z!{&[!{!|&[~&oOZ~~&tOU~~&yO_~~'OOX~~'TOV~~'YOS~~'_Oj~",
  tokenizers: [0, 1],
  topRules: {"Level":[0,1]},
  tokenPrec: 0
})
